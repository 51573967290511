// * IMPORT FONT ROBOTO GOOGLE
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import { Routing } from './router/Routing';



function App() {
	return (
		<div className="App">
			{/* Cargando toda la configuracion de rutas */}
			<Routing />
		</div>
	);
}

export default App;

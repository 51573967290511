import React, { useState } from 'react';
import useAuth from '../../hooks/useAuth';

// * IMPORTS HELPERS
import { Global } from '../../helpers/Global';

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// * IMPORTS MATERIAL UI - ICONS
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

// * IMPORTS MATERIAl UI - SPEED DIAL
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

// * IMPORT MATERIAL UL - MODAL
import Modal from '@mui/material/Modal';

// * IMPORTS MATERIAL UL - PROGRESS SPINNER
import CircularProgress from '@mui/material/CircularProgress';

// * IMPORT MATERIAL UI ALERT TOAST
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// * IMPORTS DROPZONE - DRAG & DROP
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

// * IMPORTS FORM
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// * IMPORTS MEDIA CUSTOM
import 	img_ine from '../../assets/img/onboarding/campos-ine.png';

// * Config CSS Modal
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

export const DocumentId = () => {

	// * HOOKS
	// Conseguir los datos de usuario y onboarding
	const {auth, onboarding} = useAuth();
    // console.log(auth);
	console.log(onboarding.data);
	// INE
	const [validateTypeINE, setValidateTypeINE] = useState(false);
	// Loading
	const [loading, setLoading] = useState(false);
	// Modal
	const [open, setOpen] = useState(false);
	// Speeddeal
	const [direction] = useState('left');
	// Snackbar
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [messageSnackbar, setMessageSnackbar] = useState("");
  	const [severitySnackbar, setSeveritySnackbar] = useState("");


	// * FUNCTIONS INE
	const validateINEByNubarium = async (event) => {

		event.preventDefault();

		// Mostrar loading mientras se valida el INE
		setLoading(true);

		// Obetener los datos de INE
        let dataForm = new FormData(event.currentTarget);
		let dataINE = JSON.stringify({cic: dataForm.get('cic'), identificadorCiudadano: dataForm.get('identificadorCiudadano')});

		// Preparar la peticion al API
		let requestOptions = {
			method: 'POST',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			body: dataINE,
			redirect: 'follow'
		};

		try {
			const request = await fetch(`${Global.url_api}api/validateINE`, requestOptions);
			const response =  await request.json();

			// Convertir la respuesta en un objeto
			let result = JSON.parse(response);

			console.log(result);

			let {estatus, mensaje} = result;

			if (estatus === 'ERROR') {
				// Mostrar mensaje de consulta fallida
				showStatusMessage(mensaje, 'error');
				// Ocultar loading mientras se valida el INE
				setLoading(false);
				return false;
			}
			
			// Ocultar loading mientras se valida el INE
			setLoading(false);

			// Setear el valor para validar INE
			setValidateTypeINE(true);

			// Mostrar mensaje de consulta exitosa
			showStatusMessage(mensaje, 'success');

		} catch (error) {
			showStatusMessage('ERROR AL SOLICITAR WEBSERVICE - CODE 500', 'error');
			console.log(error);
		}

    };

	const saveINE = async (files) => {

		if (!files[0] || !files[1]) {
			showStatusMessage('INE Frente e INE Reverso son obligatorios', 'warning');
			return false;
		}

		// Sacar datos usaurio identificado del localstorage
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");

        // Comprobar si tengo el token y el user
        if (!token || !user) {
            return false;
        }

		const data = new FormData();
		data.append('file0', files[0].file, files[0].file.name);
		data.append('file1', files[1].file, files[1].file.name);
		data.append('idUser', auth.id);
		data.append('validationINE', validateTypeINE);

	
		let requestOptions = {
			method: 'POST',
			headers: {
				"Authorization" : `Bearer  ${token.replace(/['"]+/g, '')}`
			},
			body: data,
			redirect: 'follow'
		};

		try {
			const request = await fetch(`${Global.url_api}api/upload-ine`, requestOptions);
			const response =  await request.json();
			console.log(response);
			showStatusMessage('¡Documento INE almacenado correctamente!', 'success');

			// Recargar la pagina para reflejar cambios
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		} catch (error) {
			console.log(error);
		}
	}


	// * FUNCTIONS SNACKBAR
	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	const showStatusMessage = (message, type) => {
		setOpenSnackbar(true);
		setMessageSnackbar(message);
		setSeveritySnackbar(type);
	};

	const closeSnackbar = (event, reason) => {
		if (reason === "clickaway") {
		  return;
		}
		setOpenSnackbar(false);
	};


	// * FUNCTIONS MODAL
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);


	// * ACTIONS
	// SpeedDial
	const actionsSpeedDeal = [		
		{ icon: <DeleteOutlineOutlinedIcon onClick={handleOpen} />, name: 'Eliminar' },
		{ icon: <CloudDownloadOutlinedIcon />, name: 'Descargar' },
		// { icon: <CreateOutlinedIcon />, name: 'Editar' },
		// { icon: <VisibilityOutlinedIcon />, name: 'Ver' },
	];

	return (
		<div>
			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
				<Alert onClose={closeSnackbar} severity={severitySnackbar}>
					{messageSnackbar}
				</Alert>
			</Snackbar>

			<Container component="main" maxWidth="lg">
				
				<Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor:'white', padding: '15px', borderRadius: '15px' }} >

					<Grid container spacing={2} sx={{ mt:-1, width: '100%' }} >
						<Grid item xs={12}>
							<SpeedDial
								ariaLabel="SpeedDial basic example"
								direction={direction}
								icon={<SpeedDialIcon />}
							>
								{actionsSpeedDeal.map((action) => (
									<SpeedDialAction
										key={action.name}
										icon={action.icon}
										tooltipTitle={action.name}
									/>
								))}
								<SpeedDialAction
										key={"Editar"}
										icon={<CreateOutlinedIcon />}
										tooltipTitle={"Editar"} />
								<SpeedDialAction
										onClick={handleOpen}
										key={"Ver"}
										icon={<VisibilityOutlinedIcon />}
										tooltipTitle={"Ver"} />
							</SpeedDial>
						</Grid>
					</Grid>
					
					<Typography component="h1" variant="h6" sx={{ mt:2 , mb:0 }}>
						¡{auth.name}, en el siguiente apartado puede seleccionar/arrastar tú INE!
					</Typography>
					<Typography component="h1" variant="caption" sx={{ mt:0 , mb:0 }}>
							1.- Cargar INE Frente
							<br />
							2.- Cargar INE Reverso
					</Typography>
					<Grid container spacing={2} sx={{ mt:-1, width: '100%' }} >
						<Grid item xs={12} sx={{ mt:2 }}>
							<Dropzone
								onSubmit={saveINE}
								accept="image/*"
							/>
						</Grid>
					</Grid>
								

					<Typography component="h1" variant="h5" sx={{ mt: 10, mb:3 }}>
						Validar INE Tipo D
					</Typography>

					<img src={img_ine} className="img-ine" alt="img-ine"  />
					
					<Box component="form" onSubmit={validateINEByNubarium} noValidate sx={{ mt: 1 }}>

						<TextField margin="normal"  required fullWidth id="cic" name="cic" label="CÓDIGO DE IDENTIFICACIÓN DE LA CREDENCIAL (CIC) (173438871)"  type="number" autoFocus />

						<TextField margin="normal"  required fullWidth name="identificadorCiudadano" id="identificadorCiudadano" label="IDENTIFICADOR DEL CIUDADANO (098794022)" type="number"  />
						
						<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} > Verificar </Button>
						
					</Box>
					
					{
						(loading === true) &&
							<>
								<Box sx={{ mt: 1 }}>
									<Box sx={{ display: 'flex' }}>
										<CircularProgress />
									</Box>
								</Box>
							</>
					}

				</Box>
			</Container>

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						INE
					</Typography>
					<Typography component={'span'} id="modal-modal-description" sx={{ mt: 2 }}>	
						<Grid container spacing={2} sx={{ mt:-1, width: '100%' }} >
							<Grid item xs={12}>
								<img src={`${Global.url_root}storage/app/documents/ine/${onboarding.data.img_ine_frente}`} alt={onboarding.data.id} className="img-ine-modal" />
							</Grid>
							<Grid item xs={12}>
								<img src={`${Global.url_root}storage/app/documents/ine/${onboarding.data.img_ine_reverso}`} alt={onboarding.data.id} className="img-ine-modal" />
							</Grid>
						</Grid>
					</Typography>
				</Box>
			</Modal>


		</div>
	)
}

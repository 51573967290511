import React, { useState } from 'react';
import { useForm } from '../../hooks/useForm';

// * IMPORTS ROUTING 
import { NavLink } from 'react-router-dom';

// * IMPORTS HELPERS
import { Global } from '../../helpers/Global';

// * IMPORT ASSETS
import spingo_logo from '../../assets/img/general/spingo-logo-light.png';

// * IMPORTS MATERIAL UI - REGISTER
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// * IMPORT MATERIAL UI ALERT TOAST
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const theme = createTheme();

export const Register = () => {

    // * HOOKS
	// Register User
    const {form, changed} = useForm({});
	// Snackbar
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [messageSnackbar, setMessageSnackbar] = useState("");
  	const [severitySnackbar, setSeveritySnackbar] = useState("");

    // * FUNCTION REGISTER USER
    const saveUser = async (event) => {
        // Prevenir actualizar pantalla
		event.preventDefault();

        // Recoger datos del formulario
        let newUser = form;
        newUser.fecha_nac = "1997/08/16";


        let {name, middle_name, last_name, telephone, email, password, password_repet} = newUser;


        if (!name || !middle_name || !middle_name || !last_name || !telephone || !email || !password || !password_repet) {
			showStatusMessage('¡Todos los campos son obligatorios!', 'info');
            return false;
        }

        // Prepara request api
        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(newUser),
            headers : {
                "Content-Type" : "application/json"
            },  
            redirect: 'follow'
        };

        // Guardar usuario en el backend
        const request = await fetch(`${Global.url_api}api/auth/register`, requestOptions);

        const data = await request.json();

        // Validar respuestas del backend
        if (data.message && data.message === 'success create user') {
            showStatusMessage('¡Registro Exitoso!', 'success');
        }
        else if(data.email || data.password){
            showStatusMessage('¡El correo electrónico ya ha sido registrado!', 'error');
            return false;
        }else{
            showStatusMessage('¡Error inesperado!', 'error');
        }
    };

    // * FUNCTIONS SNACKBAR
	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	const showStatusMessage = (message, type) => {
		setOpenSnackbar(true);
		setMessageSnackbar(message);
		setSeveritySnackbar(type);
	};

	const closeSnackbar = (event, reason) => {
		if (reason === "clickaway") {
		  return;
		}
		setOpenSnackbar(false);
	};

    // * FUNCTIONS LAYOUT
    const Copyright = (props) => {
		return (
			<Typography variant="body2" color="text.secondary" align="center" {...props}>
				{'Derechos Reservados © '}
				<Link color="inherit" href="https://spingo.mx/">
					SpinGo
				</Link>{' '}
				{new Date().getFullYear()}
				{'.'}
			</Typography>
		);
	}

    return (
        <ThemeProvider theme={theme}>

			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
				<Alert onClose={closeSnackbar} severity={severitySnackbar}>
					{messageSnackbar}
				</Alert>
			</Snackbar>

			<Container component="main" maxWidth="sm">

				<CssBaseline />
				<Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor:'white', padding: '15px', borderRadius: '10px' }} >
					<img src={spingo_logo} className="img-logo" alt="logo-spingo" />

					<Typography component="h1" variant="h5">
						Bienvenido
					</Typography>

					<Box component="form" onSubmit={saveUser} noValidate sx={{ mt: 1 }}>

                        <TextField onChange={changed} error = {!form.name ? true : false } helperText = {!form.name ? "Campo obligatorio" : false } margin="normal" required fullWidth id="name" label="Nombre" name="name"  autoComplete="off" autoFocus />

                        <TextField onChange={changed} error = {!form.middle_name ? true : false } helperText = {!form.middle_name ? "Campo obligatorio" : false } margin="normal" required fullWidth id="middle_name" label="Apellido Paterno" name="middle_name" autoComplete="off" />

                        <TextField onChange={changed} error = {!form.last_name ? true : false } helperText = {!form.last_name ? "Campo obligatorio" : false }  margin="normal" required fullWidth id="last_name" label="Apellido Materno" name="last_name" autoComplete="off"  />

                        <TextField onChange={changed} error = {!form.telephone ? true : false } helperText = {!form.telephone ? "Campo obligatorio" : false }  margin="normal" required fullWidth id="telephone" label="Celular" name="telephone" autoComplete="off" type="number" />

						<TextField onChange={changed} error = {!form.email ? true : false } helperText = {!form.email ? "Campo obligatorio" : false }  margin="normal" required fullWidth id="email" label="Correo Electrónico" name="email" autoComplete="email"  />

						<TextField onChange={changed} error = {!form.password ? true : false } helperText = {!form.password ? "Campo obligatorio" : false }  margin="normal" required fullWidth name="password" label="Contraseña" type="password" id="password" autoComplete="current-password" />

                        <TextField onChange={changed} error = {!form.password_repet ? true : false } helperText = {!form.password_repet ? "Campo obligatorio" : false }  margin="normal" required fullWidth name="password_repet" label="Confirmar contraseña" type="password" id="password_repet" autoComplete="current-password" />

						{/* <MobileDatePicker
							label="Date mobile"
							inputFormat="MM/DD/YYYY"
							renderInput={(params) => <TextField {...params} />}
						/> */}
												
						<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} > Registrarme </Button>
					
						<Grid container>
							<Grid item xs={12}>
								<NavLink to="/login" className="navlink-item" >{"¿Ya estás registrado? Ingresa"}</NavLink>
							</Grid>
						</Grid>
					</Box>
				</Box>

				<Copyright sx={{ mt: 8, mb: 4 }} />

			</Container>

      	</ThemeProvider>
    )
}

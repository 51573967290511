import React from 'react';

import { useParams } from 'react-router-dom';

// import { DocumentBill } from './DocumentBill';
// import { DocumentCirculationCard } from './DocumentCirculationCard';
import { DocumentId } from './DocumentId';
// import { DocumentProofAddress } from './DocumentProofAddress';

export const SelectDocument = () => {
    // * Get document type from url
    const {document} = useParams();
 
    if (document === 'id') {
        return (
            <>
                <DocumentId />
            </>
        ) 
    } 
    // else if (document === 'proofaddress') {
    //     return (
    //         <>
    //             <DocumentProofAddress />
    //         </>
    //     ) 
    // }  
    // else if (document === 'circulationcard') {
    //     return (
    //         <>
    //             <DocumentCirculationCard />
    //         </>
    //     ) 
    // } 
    // else if (document === 'bill') {
    //     return (
    //         <>
    //             <DocumentBill />
    //         </>
    //     ) 
    // } 
}

import React from 'react';
import {Routes, Route, BrowserRouter, Link, /*Navigate*/} from 'react-router-dom';

import { PublicLayout } from '../components/layout/public/PublicLayout';
import { PrivateLayout } from '../components/layout/private/PrivateLayout';

import { Login } from '../components/user/Login';
import { Register } from '../components/user/Register';
import { Onboarding } from '../components/onboarding/Onboarding';
import { Logout } from '../components/user/Logout';

import { AuthProvider } from '../context/AuthProvider';

import { SelectDocument } from '../components/onboarding/SelectDocument';

export const Routing = () => {
    return (
        <BrowserRouter>

            {/* Componente que sera heredado a los componentes hijos apra tener acceso a la informacion del usuario */}
            <AuthProvider>

                <Routes>

                    {/* Primer grupo de rutas */}
                    <Route path="/" element={<PublicLayout />} >
                        <Route index element={<Login />} />
                        <Route path="login" element={<Login />} />
                        <Route path="register" element={<Register />} />
                    </Route>

                    {/* Segundo grupo de rutas */}
                    <Route path="/onboarding" element={<PrivateLayout />} >
                        <Route index element={<Onboarding />} />
                        <Route path="onboarding" element={<Onboarding />} />

                        {/* Documentos de onboarding */}
                        <Route path='documents/:document' element={ <SelectDocument/> } />

                        <Route path="logout" element={<Logout />} />
                    </Route>

                    {/* Ruta 404 */}
                    <Route path="*" element={
                        <>
                            <h1>ERROR 404</h1>
                            <Link to="/">Volver al inicio</Link>
                        </>
                    } />

                </Routes>
            </AuthProvider>
        </BrowserRouter>
    )
}

import React from 'react';


// * IMPORTS REACT ROUTER
import { Link } from 'react-router-dom';

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// * IMPORTS MATERIAL UI - CARDS
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// * IMPORTS MATERIAl UI -  ICONS
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AddHomeWorkOutlinedIcon from '@mui/icons-material/AddHomeWorkOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
// import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';



export const Onboarding = () => {

    // * HOOKS

    return (
        <div>
            <Container maxWidth="md" sx={{ mt:5 }} >

                <Typography gutterBottom variant="h5" component="div" sx={{ mb:5 }} >
                    Bienvenido: nombre paterno materno
                </Typography>

                <Grid container spacing={5} >

                    <Grid item xs={12} md={6} xl={6} container direction="row" alignItems="center" justifyContent="center">
                        <Card sx={{ maxWidth: 345, minWidth: 345 }}>
                            <BadgeOutlinedIcon color="primary" sx={{ fontSize: 100, mt:5 }} />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    INE
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <CircleOutlinedIcon color="action" sx={{ fontSize: 50, mt:0 }} />
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <AddCircleOutlineOutlinedIcon color="primary" />
                                <Button size="small" sx={{ mt:0.3 }} ><Link to="/onboarding/documents/id" className="onboarding-item-text" >Agregar Documento</Link></Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6} xl={6} container direction="row" alignItems="center" justifyContent="center">
                        <Card sx={{ maxWidth: 345, minWidth: 345 }}>
                            <AddHomeWorkOutlinedIcon color="primary" sx={{ fontSize: 100, mt:5 }} />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Comprobante de Domicilio
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {/* <CancelOutlinedIcon color="action" sx={{ fontSize: 50, mt:0, color: "#ff0000b3" }} /> */}
                                    <CircleOutlinedIcon color="action" sx={{ fontSize: 50, mt:0 }} />
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <AddCircleOutlineOutlinedIcon color="primary" />
                                <Button size="small" sx={{ mt:0.3 }} ><Link to="/onboarding/documents/proofaddress" className="onboarding-item-text" >Agregar Documento</Link></Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6} xl={6} container direction="row" alignItems="center" justifyContent="center">
                        <Card sx={{ maxWidth: 345, minWidth: 345 }}>
                            <DirectionsCarFilledOutlinedIcon color="primary" sx={{ fontSize: 100, mt:5 }} />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                Tarjeta de Circulación
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {/* <CheckCircleOutlineOutlinedIcon color="success" sx={{ fontSize: 50, mt:0 }} /> */}
                                    <CircleOutlinedIcon color="action" sx={{ fontSize: 50, mt:0 }} />
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <RemoveRedEyeOutlinedIcon color="primary" />
                                <Button size="small" sx={{ mt:0.3 }} ><Link to="/onboarding/documents/id" className="onboarding-item-text" >Agregar Documento</Link></Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6} xl={6} container direction="row" alignItems="center" justifyContent="center">
                        <Card sx={{ maxWidth: 345, minWidth: 345 }}>
                            <PlagiarismOutlinedIcon color="primary" sx={{ fontSize: 100, mt:5 }} />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Factura
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {/* <CircleOutlinedIcon color="action" sx={{ fontSize: 50, mt:0 }} /> */}
                                    <CircleOutlinedIcon color="action" sx={{ fontSize: 50, mt:0 }} />
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <AddCircleOutlineOutlinedIcon color="primary" />
                                <Button size="small" sx={{ mt:0.3 }} ><Link to="/onboarding/documents/id" className="onboarding-item-text" >Agregar Documento</Link></Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

        </div>
    );
}

import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

import useAuth from '../../../hooks/useAuth';
import { Header } from './Header';

export const PublicLayout = () => {

    const {auth} = useAuth();

    return (
        <>
            {/* LAYOUT */}
            <Header />

            {/* CONTENIDO PRINCIPAL */}
            <section>
                {/* Si el usuario esta identificado lo redireccionamos a onboarding, caso contrario se redirecciona a login/registro */}
                {!auth.id ?
                    <Outlet />
                :
                    <Navigate to="/onboarding" />
                }
            </section>
        </>
    )
}

import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { Header } from './Header';

// * IMPORTS MATERIAl UI - LINEAR PROGRESS
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export const PrivateLayout = () => {

    const {auth, loading} = useAuth();


    if (loading) {
        return (

            <Container maxWidth="md" sx={{ mt:30 }} >
                <Grid container spacing={5} >
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                </Grid>
            </Container> 
        );
        
    } else {
        return (
            <>
                {/* LAYOUT */}
                <Header />
    
                {/* CONTENIDO PRINCIPAL */}
                <section>
                    {auth.id ?
                        <Outlet />
                    :
                        <Navigate to='/login' />
                    }
                </section>
            </>
        );  
    }
}

import React, { useState } from 'react';
import { useForm } from '../../hooks/useForm';
import useAuth from '../../hooks/useAuth';

// * IMPORTS ROUTING 
import { NavLink } from 'react-router-dom';

// * IMPORTS HELPERS
import { Global } from '../../helpers/Global';

// * IMPORT ASSETS
import spingo_logo from '../../assets/img/general/spingo-logo-light.png';

// * IMPORTS MATERIAL UI - LOGIN
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// * IMPORT MATERIAL UI ALERT TOAST
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const theme = createTheme();

export const Login = () => {

    // * HOOKS
	// Auth
	const {setAuth} =  useAuth();
	// Register User
    const {form, changed} = useForm({});
	// Snackbar
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [messageSnackbar, setMessageSnackbar] = useState("");
  	const [severitySnackbar, setSeveritySnackbar] = useState("");

    // * FUNCTION REGISTER USER
    const loginUser = async (event) => {
        // Prevenir actualizar pantalla
		event.preventDefault();

        // Recoger datos del formulario
        let userToLogin = form;

        // Validacion de datos
        let {email, password} = userToLogin;

        if (!email || !password) {
			showStatusMessage('¡Todos los campos son obligatorios!', 'info');
            return false;
        }

        // Prepara request api
        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(userToLogin),
            headers : {
                "Content-Type" : "application/json"
            },  
            redirect: 'follow'
        };

        // Peticion al backend
        const request = await fetch(`${Global.url_api}api/auth/login`, requestOptions);

        const data = await request.json();

        // Validar respuestas del backend
        if (data.access_token && data.access_token !== '') {

            // Prepara request api
            let requestOptions = {
                method: 'POST',
                headers: {
                    "Content-Type" : "application/json",
                    "Authorization" : `Bearer  ${data.access_token.replace(/['"]+/g, '')}`
                },
                body: {},
                redirect: 'follow'
            };

            // Peticion al backend para obtener los datos del usuario
            const requestMe = await fetch(`${Global.url_api}api/auth/me`, requestOptions);

            const dataMe = await requestMe.json();

            // Persistir los datos en el navegador
            localStorage.setItem("token", data.access_token);
            localStorage.setItem("user", JSON.stringify(dataMe));

            showStatusMessage('¡Usuario identificado correctamente!', 'success');

			// Setear los datos en el auth de AuthProvider
			setAuth(dataMe);

			// Redireccion a GUI Onboarding
			setTimeout(() => {
				window.location.reload();
			}, 2000);
        }
        else if(data.error && data.error === 'Unauthorized'){
            showStatusMessage('¡Usuario no identificado!', 'error');
            return false;
        }else{
            showStatusMessage('¡Error inesperado!', 'error');
        }

    };

    // * FUNCTIONS SNACKBAR
	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	const showStatusMessage = (message, type) => {
		setOpenSnackbar(true);
		setMessageSnackbar(message);
		setSeveritySnackbar(type);
	};

	const closeSnackbar = (event, reason) => {
		if (reason === "clickaway") {
		  return;
		}
		setOpenSnackbar(false);
	};

    // * FUNCTIONS LAYOUT
    const Copyright = (props) => {
		return (
			<Typography variant="body2" color="text.secondary" align="center" {...props}>
				{'Derechos Reservados © '}
				<Link color="inherit" href="https://spingo.mx/">
					SpinGo
				</Link>{' '}
				{new Date().getFullYear()}
				{'.'}
			</Typography>
		);
	}

    return (
        <ThemeProvider theme={theme}>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
				<Alert onClose={closeSnackbar} severity={severitySnackbar}>
					{messageSnackbar}
				</Alert>
			</Snackbar>

			<Container component="main" maxWidth="sm">

				<CssBaseline />
				<Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor:'white', padding: '15px', borderRadius: '10px' }} >
					<img src={spingo_logo} className="img-logo" alt="logo-spingo" />

					<Typography component="h1" variant="h5">
						Iniciar Sesión
					</Typography>

					<Box component="form" onSubmit={loginUser} noValidate sx={{ mt: 1 }}>

						<TextField onChange={changed} error = {!form.email ? true : false } helperText = {!form.email ? "Campo obligatorio" : false } margin="normal" required fullWidth id="email" label="Correo Electrónico" name="email" autoComplete="email" autoFocus />

						<TextField onChange={changed} error = {!form.password ? true : false } helperText = {!form.password ? "Campo obligatorio" : false } margin="normal" required fullWidth name="password" label="Contraseña" type="password" id="password" autoComplete="current-password" />
						
						{/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Recordarme" /> */}
						
						<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} > Iniciar Sesión </Button>

						<Grid container>
							<Grid item xs>
								<Link href="#" variant="body2"> ¿Has olvidado tu contraseña? </Link> 
							</Grid>
							<Grid item> 
								<NavLink to="/register" className="navlink-item" >{"¿No tienes una cuenta? Registrate"}</NavLink>
							</Grid>
						</Grid>
					</Box>
				</Box>

				<Copyright sx={{ mt: 8, mb: 4 }} />

			</Container>

      	</ThemeProvider>
    )
}
